import React from 'react';
import { ShellGame } from './ShellGame/ShellGame';

export const App = () =>  {
  return (
    <div>
      <ShellGame />
    </div>
  );
}

